import {Card} from "primereact/card"
import loginPicture from "../assets/CV_Login.png"
import React, {useState} from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {useNavigate} from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const navigate = useNavigate()

    // Define the mutation using useMutation hook from React Query
    // const forgotPasswordMutation = useMutation(async (email) => {

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setError('') // Clear any previous errors

        if (!email) {
            setError('Please enter your email address.')
            return
        }

        // Trigger the mutation
        // forgotPasswordMutation.mutate(email)
    }

    const header = <img alt="logo" src={loginPicture} className="w-4/5 mx-auto"/>

    return (
        <div className="App">
            <div className="card p-4">
                <Card header={header} className="align-items-center w-4 mt-4 shadow-3" style={{ translate: '100%' }}>
                    <form onSubmit={handleSubmit} className="flex flex-column align-items-center">
                        <h5 className="pt-2 pl-2">Forgot Password</h5>
                        <div className="p-fluid mt-4 mb-4">
                            <span className="p-float-label">
                                <InputText
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={error ? 'p-invalid' : ''}
                                />
                                <label htmlFor="email">Email</label>
                            </span>
                            {error && <small className="p-error">{error}</small>}
                        </div>
                        <Button
                            label="Send Reset Link"
                            type="submit"
                            className="mt-2"
                            // loading={forgotPasswordMutation.isLoading} // Show loading state on button
                        />
                    </form>
                </Card>
            </div>
        </div>
    )
}

export default ForgotPassword